<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-message-form" @submit.prevent="submit">
    <div class="left">
      <div class="form-row">
        <p-text-field v-model="name" :disabled="!$hasPermission('templates')" :label="'Name'" autocomplete="off" />
        <ul v-if="$v.name">
          <li v-if="!$v.name.required" class="error">Name is required</li>
        </ul>
      </div>
      <div class="form-row">
        <p-text-field v-model="description" :disabled="!$hasPermission('templates')" :label="'Description'" autocomplete="off" />
      </div>
      <div class="form-row">
        <p-tags
          v-model="clients"
          label="Clients"
          placeholder=""
          :disabled="!$hasPermission('templates')"
          :validation="[]"
          :options="getClientsOptions"
          :add-only-from-autocomplete="true"
          :autocomplete-min-length="0"
        />
      </div>
      <div class="form-row">
        <p-tags v-model="tags" label="Tags" :disabled="!$hasPermission('templates')" placeholder="" :validation="[]" :options="getTagsOptions" />
      </div>

      <div v-if="isRequestFailed" class="error">Failed to save a message. Check your input and try again.</div>
      <div class="form-row submit">
        <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
        <p-button v-if="$hasPermission('templates')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
          >Save</p-button
        >
      </div>
    </div>
    <div class="right">
      <div class="form-row code">
        <p-code v-model="template" :read-only="!$hasPermission('templates')" :language="'handlebars'"></p-code>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import Tags from '@/components/common/Tags';
import Code from '@/components/common/Code';

import httpClient from '@/utils/httpClient';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-code': Code,
    'p-tags': Tags
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        name: this.item.name,
        description: this.item.description,

        template: this.item.template,

        clients: this.item.clients,
        tags: this.item.tags
      };
    }
    return {
      name: '',
      description: '',

      template: '',

      clients: [],
      tags: []
    };
  },
  computed: {},
  validations() {
    return {
      ...(this.item === null ? { name: { required } } : {})
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        name: this.name.trim(),
        description: this.description,
        template: this.template,
        clients: this.clients,
        tags: this.tags
      });

      this.$v.$reset();
    },

    async getClientsOptions(q) {
      return httpClient.get(`/api/organizations/suggest?q=${q}`);
    },
    async getTagsOptions(q) {
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-message-form {
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  display: grid;

  > div {
    min-width: 0;
    min-height: 0;
  }
  .left {
    grid-column: 1/2;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;
    overflow-y: auto;

    > div {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .right {
    grid-column: 2/3;
    grid-row: 1/2;
    background: var(--theme-surface);
    padding: 2rem;

    .code {
      height: 100%;
    }
  }

  .submit {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  // > * {
  //   &:not(:last-child) {
  //     margin-bottom: 1.5rem;
  //   }
  //   &:last-child {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }

  // .form-row {
  //   &.template {
  //     grid-column: 1/2;
  //     grid-row: 1/2;
  //   }
  //   &.name {
  //     grid-column: 1/2;
  //     grid-row: 2/3;
  //   }
  //   &.description {
  //     grid-column: 1/2;
  //     grid-row: 3/4;
  //   }
  //   &.tags {
  //     grid-column: 1/2;
  //     grid-row: 4/5;
  //   }

  //   &.code {
  //     grid-column: 2/3;
  //     grid-row: 1/5;
  //   }
  //   &.submit {
  //     grid-column: 1/3;
  //     grid-row: 5/6;
  //   }
  // }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
